import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection } from "../Elements"

const FormWrapper = styled(PurpleSection)`
  .contact-card {
    border-radius: 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(var(--hlc2-navy-text), 0.34);
    padding: 1rem 2rem;
    margin: 2rem;

    h1 {
      color: var(--hlc-white);
    }
    .contact-form {
      display: flex;
      flex-direction: column;
      font-family: neuzeit-grotesk, sans-serif;
      label {
        color: var(--hlc-teal);
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }

      input {
        height: 2rem;
      }

      input,
      textarea {
        background: var(--hlc-purple);
        border: none;
        border-bottom: 2px solid var(--hlc-white);
        color: var(--hlc-white);
        width: 100%;
        margin-bottom: 1rem;
        line-height: 1.6rem;
        padding: 0 0 0 2px;
      }

      input::placeholder,
      textarea::placeholder {
        color: var(--hlc-white);
        opacity: 0.7;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        list-style: none;
        padding: 0;

        .special {
          background: var(--hlc-teal);
          color: var(--hlc-purple);
          border: 1px solid var(--hlc-teal);
        }
        li {
          margin-left: 1rem;

          input {
            background: var(--hlc-purple);
            border-radius: 0.25rem;
            border: 1px solid var(--hlc-purple);
            color: var(--hlc-white);
            font-weight: 700;
            padding: 0.5rem 2rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`

const CON01Form = () => {
  return (
    <FormWrapper>
      <Grid>
        <div className="grid-content">
          <div className="contact-card">
            <h1>Let's Talk.</h1>
            <p>
              Can't connect with us using the methods above? When there's no
              other way, submit the following form and a notification will be
              sent to our #general Slack channel.
            </p>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className="contact-form"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="half-field">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Jane Doe"
                />
              </div>
              <div className="half-field">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="jane@domain.ca"
                />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="6"
                  placeholder="Say something nice..."
                />
              </div>
              <ul className="actions">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="special"
                  />
                </li>
                <li>
                  <input type="reset" value="Clear" className="clear" />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </Grid>
    </FormWrapper>
  )
}

export default CON01Form
