import React from "react"
import { Layout, Head } from "../components/Layout"
import Banner from "../components/Banner"
import CON01Form from "../components/Contact/CON01Form"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact Us" />
      <Banner imageName="contact_graded.jpg" title="Contact" />
      <CON01Form />
    </Layout>
  )
}
export default ContactPage
